.work-container {
    display: block;
}

.work {
    display: grid;
    grid-template-columns: auto auto;
}

.work-text {
    margin-left: 30px;
    margin-bottom: 30px;
}

.content-wrap {
    margin-top: 150px;;
}

.work-title {
    display: inline;
}

.work img {
    width: 200px;
}

* {
    font-family: Open Sans, 'sans serif';
}

@media screen and (max-width: 960px) {
    .work {
        display: block;
    }

    .work-text {
        margin-left: 0;
    }

    .title-box {
        margin-top: 20px;
    }

    .work-title {
        display: block;
        margin-top: 0;
        margin-bottom: 0;
    }

}