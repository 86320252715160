
.NavbarItems {
    background: none;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 100%;
    position: fixed;
    transition: background .3s ease-out
}

.NavbarItemsColored {
    background: #333;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    width: 100%;
    position: fixed;
    z-index: 1;
    transition: background .3s ease
}

.navbar-logo {
    color: white;
    justify-self: start;
    margin-left: 20px;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    color: white;
    border-radius: 4px;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: rgb(224, 224, 224);
    transition: all .2s ease-out;
}

.fa-bars {
    color:white;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: fixed;
        z-index: 1;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 60px;
        left: -200%;
        opacity: 1;
        transition: all .5s ease;
        padding: 0;
    }

    .nav-menu.active {
        background: rgb(117,117,117);
        left: 0;
        transition: all .5s ease;
    }

    .nav-links {
        text-align: center;
        padding: 2.1rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: lightgrey;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(20%, 10%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color:white;
        font-size: 2rem;
    }
}