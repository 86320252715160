.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content-wrap {
  flex: 1;
  text-align: center;
  margin-top: 30vh;
  font-size: xx-large;
}
